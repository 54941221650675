import Axios from 'axios';

const API_URL = 'https://inventory-api.mapps.luxie.tech'
// const API_URL = 'https://czrc-inventory-api.mapps.luxie.tech'

export const request = (method, url, data = null) => {
    if (data) {
        return Axios.request({
            method: method,
            url: API_URL + url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        })
    } else {
        return Axios.request({
            method: method,
            url: API_URL + url,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        })
    }
}